<template>
    <div class="main-wrapper">
        <el-scrollbar class="main-wrapper-scrollbar">
            <div class="wrapper-list">
                <div class="top-title">
                    <span class="title">{{examInfo.op_name}}</span>
                    <span class="title-1" style="opacity: 0">{{examInfo.op_name}}</span>
                    <!-- <el-button class="backBtn" type="primary" @click="goBack">返回</el-button> -->
                </div>
                <img src="../../../../assets/images/student/bottom-line.png" style="max-width: 100%; display: block"
                     alt="">
                <div class="time-item">
                    <div class="time-item-list">
                        <div class="time-title-left">
                            <span class="comma" style="margin-left: 22px">,,,</span>
                            <div class="item">
                                <span class="score-title">本题分值：{{examInfo.score}}分</span>
                                <span style="margin-left: 50px">考核时间{{examInfo.assess_time}}分钟</span>
                            </div>
                            <span class="comma">,,,</span>
                        </div>
                        <template v-if="examInfo.exam_module_id === 42">
                            <div class="start-tex" @click="startTest">{{this.opId?'开始训练':'开始考试'}}</div>
                        </template>
                        <template v-else-if="examInfo.exam_module_id === 43">
                            <template v-if="isExamOrTrain">
                                <router-link to="/chat" target="_blank" class="start-tex">开始考试</router-link>
                            </template>
                            <template v-else>
                                <router-link :to="{path: '/chat', query: {id: this.opId, course_id: this.courseId, chapter: this.chapter, node: this.node}}" target="_blank" class="start-tex">开始训练</router-link>
                            </template>
                        </template>      
                        <template v-else-if="examInfo.exam_module_id === 35">
                            <template v-if="isExamOrTrain">
                            <router-link :to="{
                            path: '/student/exam/communitypromote/index',
                            query:{
                                eaxm_id:isExamOrTrain,
                                  file_download: examInfo.file_download
                            }
                        }" target="_blank" class="start-tex">开始考试</router-link>
                         </template>
                         <template v-else>
                                <router-link :to="{
                            path: '/student/trainCenter/startTraining/communitypromote/index',
                            query:{
                                op_id: examInfo.id,
                                course_id: courseId,
                                  file_download:examInfo.file_download
                            }
                        }" target="_blank" class="start-tex">开始训练</router-link>
                            </template>
                        </template>
                  
                    </div>
                </div>
                <!--S 任务目标-->
                <!-- <div class="mission-wrapper">
                    <div class="mission-title">
                        <span class="line"></span>
                        <span class="mission-title-text">任务目标</span>
                    </div>
                    <div class="task-item" v-for="(item, index) in examInfo.task_goal" :key="index">
                        <div class="task-bg">
                            <img src="../../../../assets/images/student/task-bg.png" alt="">
                            <p class="num-title"><em style="color: #FEDB2E">N0.1</em>任务{{item.index}}</p>
                        </div>
                        <div class="task-content">
                            <p class="text">{{item.target}}</p>
                        </div>
                    </div>
                </div> -->
                <!--E 任务目标-->
                <!--S 任务背景-->
                <div class="mission-wrapper">
                    <!-- <div class="mission-title">
                        <span class="line"></span>
                        <span class="mission-title-text">任务背景</span>
                    </div> -->
                    <div class="task-item">
                        <div class="task-content">
                            <p class="text" v-html="examInfo.task_background"></p>
                        </div>
                    </div>
                </div>
                <!-- <div class="mission-wrapper" v-if="examInfo.task_operation">
                    <div class="mission-title">
                        <span class="line"></span>
                        <span class="mission-title-text">任务操作</span>
                    </div>
                    <div class="task-item">
                        <div class="task-content">
                            <p class="text" v-html="examInfo.task_operation"></p>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="mission-wrapper" v-if="examInfo.scoring_criteria">
                    <div class="mission-title">
                        <span class="line"></span>
                        <span class="mission-title-text">评分标准</span>
                    </div>
                    <div class="task-item">
                        <div class="task-content">
                            <el-table :data="examInfo.scoring_criteria" class="greenTable">
                                <el-table-column prop="id" label="序号" width="100" align="center">
                                    <template slot-scope="scope">
                                        <span>{{scope.row.id ? scope.row.id : scope.$index + 1}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="content" label="考核内容" align="center"></el-table-column>
                                <el-table-column prop="require" label="考核要点" align="center"></el-table-column>
                                <el-table-column prop="score" label="配分" width="100" align="center"></el-table-column>
                                <el-table-column prop="standard" label="评分标准" align="center"></el-table-column>
                            </el-table>
                        </div>
                    </div>
                </div> -->
            </div>
        </el-scrollbar>
    </div>
</template>
<script>
    import {stuOpGetExamInfo} from '@/utils/apis'

    export default {
        name: "NetworkPromotion",
        data() {
            return {
                opId: Number(this.$route.query.id) || null,
                courseId: Number(this.$route.query.course_id) || null,
                examInfo: {
                    id: null, //1
                    op_name: '', //名称1
                    score: null, //分数1
                    assess_time: null, //时间1
                    task_goal: [], //任务目标1
                    task_background: '', //任务背景1
                    task_analysis: '', //任务分析
                    task_operation: '', //任务操作
                    scoring_criteria: [], //评分标准
                    file_download: '', //素材下载地址1
                    exam_module_id: null
                },

                // 三级社会化客服需要参数
                chapter: Number(this.$route.query.chapter) || null,
                node: Number(this.$route.query.node) || null,
                isExamOrTrain: localStorage.getItem('examId'), // 有:考试， 没有:训练
            }
        },

        mounted() {
            this.getExamStuExamPage()
        },
        methods: {
            getExamStuExamPage() {
                let params = {
                    type: this.opId?'store_customer_service ':'store_customer_service_level_three '
                }
                if (this.opId) {
                    params.op_id = this.opId
                }
                stuOpGetExamInfo(params).then((res) => {
                    this.examInfo = res.data
                }).catch((err) => {
                    console.log(err);
                });
            },
            startTest() {
                let routeUrl = ''
                if(this.opId){
                    routeUrl = this.$router.resolve({
                        path: '/student/trainCenter/startTraining/cyberCustomerService/index',
                        query: {
                            id: this.opId,
                            course_id: this.courseId,
                            chapter: this.$route.query.chapter,
                            node: this.$route.query.node,
                        }
                    })
                } else {
                    routeUrl = this.$router.resolve({
                        path: '/student/exam/cyberCustomerService/index',
                        query: {

                        }
                    })
                }
                window.open(routeUrl.href, '_blank');
            },
            goBack() {
                this.$router.go(-1)
            }
        }
    }
</script>

<style scoped lang="scss">
    .main-wrapper {
        background: url("../../../../assets/images/student/student-sct-bg.png") no-repeat;
        background-size: 100% 100%;
        height: 100%;
        position: relative;

        .main-wrapper-scrollbar {
            height: 100%;

            ::v-deep > .el-scrollbar__wrap {
                overflow-x: hidden;
            }

            .wrapper-list {
                .top-title {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    font-size: 48px;
                    color: #35c773;
                    padding-top: 20px;
                    letter-spacing: 4px;
                    font-weight: bold;
                    /*margin-bottom: 28px;*/
                    position: relative;

                    .title {
                        position: absolute;
                        top: 0;
                    }

                    &:before {
                        content: url("../../../../assets/images/student/line-left.png");
                        margin-right: 15px;
                    }

                    &:after {
                        content: url("../../../../assets/images/student/line-right.png");
                        margin-left: 15px;
                    }

                    .backBtn {
                        position: absolute;
                        right: 40px;
                        top: 0;
                    }

                }

                .time-item {
                    /*position: absolute;*/
                    /*top: 180px;*/
                    margin-top: 80px;
                    width: 100%;
                    display: flex;
                    justify-content: center;

                    .time-item-list {
                        display: flex;
                        height: 80px;
                        line-height: 80px;
                        background: #39B678;

                        .time-title-left {
                            flex: 1;
                            display: flex;
                            border: 6px solid #61a885;
                            background: #DDFDF2;

                            .comma {
                                color: #31A588;
                                font-size: 66px;
                                letter-spacing: 18px;
                                text-align: center;
                                line-height: 33px;
                                display: inline-block;
                            }

                            .item {
                                flex: 1;
                                font-size: 24px;
                                padding: 0 40px;
                                color: #31A588;
                                position: relative;

                                &:after {
                                    content: '';
                                    height: 40px;
                                    width: 3px;
                                    background: #31A588;
                                    position: absolute;
                                    top: 14px;
                                    right: 10px;
                                }

                                &:before {
                                    content: '';
                                    height: 40px;
                                    width: 3px;
                                    background: #31A588;
                                    position: absolute;
                                    top: 14px;
                                    left: 10px;
                                }
                            }
                        }

                        .start-tex {
                            width: 120px;
                            font-size: 24px;
                            color: #fff;
                            cursor: pointer;
                            text-align: center;
                        }
                    }

                }
            }

            .mission-wrapper {
                width: 100%;

                .mission-title {
                    width: 100%;
                    height: 80px;
                    text-align: center;
                    font-size: 48px;
                    font-style: italic;
                    font-weight: 600;
                    position: relative;
                    margin-top: 40px;

                    .line {
                        position: absolute;
                        top: 40px;
                        left: calc(50% - 150px);
                        width: 300px;
                        height: 20px;
                        background: #FEDB2E;
                    }

                    .mission-title-text {
                        position: inherit;
                        /*left: calc(50% - 100px);*/
                        text-align: center;
                    }
                }

                .task-item {
                    margin-top: 36px;

                    .task-bg {
                        color: #fff;
                        position: relative;
                        margin-left: 50px;

                        .num-title {
                            font-size: 34px;
                            position: absolute;
                            top: -26px;
                            left: 50px;

                            em {
                                font-style: normal;
                            }

                        }
                    }

                    .task-content {
                        background: #DDFDF2;
                        padding: 20px 20px 20px 50px;
                        margin: -20px 20px;

                        ::v-deep {
                            img {
                                max-width: 100%;
                            }

                            p {
                                margin: 0;
                            }
                        }

                        ::v-deep.text {
                            table {
                                border-collapse: collapse !important;
                                width: 100% !important;
                                border-color: transparent;
                                border-bottom: none;
                                margin-top: -4px;

                                tr {
                                    height: auto !important;

                                    &:nth-child(even) {
                                        td {
                                            background: #F2F9FA;
                                            color: #333333;
                                        }
                                    }

                                    &:nth-child(odd) {
                                        td {
                                            background: #C4F2E4;
                                        }
                                    }

                                    th {
                                        background: #56CA95;
                                    }

                                    th, td {
                                        border-right: 4px solid #fff;
                                        border-top: 4px solid #fff;

                                        &:first-child {
                                            border-left: 0;
                                            /*text-align: center;*/
                                        }

                                        &:last-child {
                                            border-right: 0;
                                            /*text-align: center;*/
                                        }
                                    }

                                    th {
                                        text-align: center;
                                        padding: 20px;
                                        height: 20px !important;
                                        line-height: 20px;
                                        box-sizing: border-box;
                                        font-size: 18px;
                                        color: #fff;
                                        white-space: nowrap;
                                    }

                                    td {
                                        height: auto !important;
                                        text-align: center;
                                        padding: 14px;
                                        font-size: 18px;
                                        color: #fff;
                                    }
                                }
                            }
                        }

                        ::v-deep.greenTable {
                            color: #333;
                            font-size: 16px;

                            th {
                                height: 60px;
                                font-weight: normal;
                                background: #C4F2E4;
                                border-top: 1px solid #eee;

                                &:first-child {
                                    border-left: 1px solid #eee;
                                }

                                &:nth-last-child(2) {
                                    border-right: 1px solid #eee;
                                }

                                &.gutter {
                                    border-right: 1px solid #eee;
                                }
                            }

                            td {
                                &:first-child {
                                    border-left: 1px solid #eee;
                                }

                                &:last-child {
                                    border-right: 1px solid #eee;
                                }
                            }

                            th, td {
                                border-right: 4px solid #fff;
                                border-top: 4px solid #fff;

                                &:first-child {
                                    border-left: 0;
                                    /*text-align: center;*/
                                }

                                &:last-child {
                                    border-right: 0;
                                    /*text-align: center;*/
                                }
                            }

                            tr {
                                height: 60px;
                                background: #F2F9FA;

                                &:nth-of-type(even) {
                                    background: #C4F2E4;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>